<script>
import penaltyApi from '@/api/penalty'

export default {
	name: "ModalPay",
	props: ['penalty'],
	data() {
		return {
			modalShow: false,
			payment: null,
			isMessageCopied: false,
		}
	},
	watch: {
		modalShow(newValue) {
			if (newValue) {
				this.payPenalty()
			}
		}
	},
	methods: {
        getLink() {
            return location.origin + this.$router.resolve({name: 'show-penalty-for-client', params: {docId: this.penalty.docId}}).href;
        },
		copyLink() {
            // this.getLink()
			this.$copyText(this.payment.link, this.$refs.copy).then(() => {
				this.isMessageCopied = true
			});
		},
        updatePenalties() {
            this.$emit('update-penalties')
        },
		payPenalty() {
			// penaltyApi.getPenalty(this.penaltyId).then(response => {
			// 	this.penalty = response.data
			// 	if (!this.penalty.dpaid) {
			// 		penaltyApi.payPenalty(this.penalty.id).then((paymentResponse) => {
			// 			this.payment = paymentResponse.data
            //             console.log(this.payment);
            //             if (!this.payment) {
            //
            //             }
			// 			if (Object.keys(this.payment).length === 0) {
			// 				this.modalShow = false
			// 			}
			// 		})

            penaltyApi.createPenaltyPayLink(this.penalty.id).then((paymentResponse) => {
                this.payment = paymentResponse.data
                if (!this.payment) {

                }
                if (Object.keys(this.payment).length === 0) {
                    this.modalShow = false
                }
            })

            // createPenaltyPayLink
            /*} else {
                this.payment = {status: 'success', message: 'іншою платформою'}
            }*/
			// })
		}
	}
}
</script>

<template>
	<div class="d-flex cursor-pointer">
<!--        <i class="icon cash"></i>-->
<!--        <span @click="modalShow = !modalShow">-->
<!--            Сплатити-->
<!--        </span>-->
		<b-modal v-model="modalShow"
                 :id="`modal-pay-${penalty.id}`"
                 @ok="updatePenalties()"
                 hide-backdrop
                 hide-header
                 hide-footer
                 centered
		>
			<template #default="{ok, cancel}">
				<div v-if="payment !== null && !payment.status" class="m-4">
					<div class="text-center w-100">
						<h1>Рахунок сформовано</h1>
					</div>
					<div class="text-center">
						<div>
							<h2>До сплати:</h2>
						</div>
						<div>
							<span> {{ penalty.sumPenalty }} грн</span>
						</div>
                        <img :src="payment.qr_link" alt="">
                        <div class="commission">
                            QR-код переходу на сторінку оплати
                        </div>
					</div>
                    <div class="commission mb-2">
                        <div>
                            +1% за послуги еквайрингу
                        </div>
                        <div>
                            +10% коміссія з платежу
                        </div>
                    </div>
					<div class="payment-link mb-4 w-100">
						<input redonly="true" :value="payment.link" disabled type="text"
									 class="policy-bill__link" ref="mylink">
						<div v-if="isMessageCopied">
							Посилання скопійовано
						</div>
						<button type="button" @click="copyLink()" ref="copy">
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none"
									 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M18 0H8C6.897 0 6 0.897 6 2V6H2C0.897 6 0 6.897 0 8V18C0 19.103 0.897 20 2 20H12C13.103 20 14 19.103 14 18V14H18C19.103 14 20 13.103 20 12V2C20 0.897 19.103 0 18 0ZM2 18V8H12L12.002 18H2ZM18 12H14V8C14 6.897 13.103 6 12 6H8V2H18V12Z"
									fill="#24282C"/>
							</svg>
						</button>
					</div>
					<div class="text-center w-100 mb-4">
						<router-link :to="{name: 'show-penalty-for-client', params: {docId: penalty.docId}}"
							 class="el-button w-100 el-button--primary"
							 @click="ok()"
						>Перейти до сплати</router-link>
					</div>
					<div class="text-center w-100">
						<el-button class="w-100 cancel-button" @click="cancel()">Скасування</el-button>
					</div>
				</div>
				<div v-else-if="payment && payment.status === 'success' && payment.message" class="text-center">
					<h1>Вже сплачено {{ payment.message }}</h1>
					<div class="text-center w-100 mb-4">
						<el-button class="w-100"
											 type="info"
											 @click="ok()"
						>ОК
						</el-button>
					</div>
				</div>
				<div v-else-if="payment && payment.status === 'success'" class="text-center">
					<h1>Вже сплачено</h1>
					<div class="text-center w-100 mb-4">
						<el-button class="w-100"
											 type="info"
											 @click="ok()"
						>ОК
						</el-button>
					</div>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<style scoped>
.el-button--primary {
    background-color: #009BEB;
    border-color: #009BEB;
}
.cancel-button {
    border-color: #009BEB;
    color: #009BEB;
}

.payment-link {
	position: relative;
	text-align: center;

	button {
		position: absolute;
		right: 15px;
		top: 12px;
		padding: 0;
		border: none;
		background: transparent;
	}

	.policy-bill__link {
		width: 100%;
		background: #F6F7FB;
		border: none;
		height: 47px;
		padding-left: 16px;
		color: #24282C;
		font-size: 15px;
		margin-bottom: 6px;
		padding-right: 53px;
		text-overflow: ellipsis;
		border-radius: 5px;
	}
}

.commission {
	font-size: 13px;
	font-weight: 400;
	line-height: 23.4px;
	text-align: center;
	color: #999DA6;
}
</style>
